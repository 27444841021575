
::v-deep .v-dialog {
    border-radius 18px
    border: 2px solid $color-bnn

    overflow-x hidden
    max-height 95dvh
    // overflow-y auto
    margin 0 0

    background-color white

    @media screen and (min-width: 768px) {
        margin 0
    }

    @media screen and (min-width: 1024px) {
        margin 0
    }
}

.checkout-modal-container {
    margin 0 auto !important
    position relative

    padding: 0
    border-radius: 18px

    width 90dvw
    max-width 100dvw
    min-width 90dvw
    position relative

    display flex
    flex-direction column

    @media screen and (min-width: 768px) {
        width 90dvw
        min-width 425px
        max-width 425px
    }

    @media screen and (min-width: 1280px) {
        // width 100%
        // max-width 1029px
        // min-width 1029px
    }

}

.checkout-modal-content {
    width 100%
    height fit-content
    min-height 500px
    // max-height calc(95vh - (56px + 82px))
    overflow-y auto
    padding 0 16px
}

.header-modal {
    position sticky
    top 0
    width 100%
    display: flex
    justify-content: space-between
    align-items: center
    background white
    z-index 10
    padding 24px 16px 0
    box-shadow 0 0px 12px 16px white
    // margin-bottom: 16px
}

.btn-close {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;

    background: #e8e8e8;
    border-radius: 50% !important;

    z-index 10

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.56)
    }
}

.checkout-modal-footer {
    position sticky
    bottom 0
    width 100%
    background white
    padding 16px 16px 24px
    z-index 10
}
